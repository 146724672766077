import axios from 'axios';

axios.interceptors.response.use(
    response => {
        return response;
    },
    async error => {
        const originalRequest = error.config;

        if (error.response && error.response.status === 419 && !originalRequest._retry) {
            originalRequest._retry = true;

            try {
                const response = await axios.get(window.appEndpoint + '/api/refresh-token');
                const csrfToken = response.data.token;
                document.querySelector('meta[name="csrf-token"]').setAttribute('content', csrfToken);

                originalRequest.headers['X-CSRF-TOKEN'] = csrfToken;
                return axios(originalRequest);
            } catch (error) {
                return Promise.reject(error);
            }
        }

        return Promise.reject(error);
    }
);



