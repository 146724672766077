<template>
    <div class="new-task">
        <div class="d-flex justify-content-between">
            <h1 class="title-main">Nowe zadanie</h1>
            <span
                class="app-btn-cancel nav-btn"
                @click="navigateToTaskResources"
                >&#10006;
            </span>
        </div>

        <div class="row p-5">
            <div class="col-8 left-section-bg py-5">
                <form @submit.prevent="submitForm">
                    <div class="d-flex flex-wrap align-items-center">
                        <small
                            v-if="!v$.due_date.required && v$.due_date.$dirty"
                            class="text-danger mr-3"
                        >
                            Date is required
                        </small>

                        <base-date-control v-model="due_date" />

                        <small
                            v-if="
                                (!v$.priority.required ||
                                    !v$.priority.numeric) &&
                                v$.priority.$dirty
                            "
                            class="text-danger mr-3"
                        >
                            Priority is required
                        </small>
                        <base-priority-control v-model="priority" />

                        <create-task-type v-on:enteredType="type = $event" />

                        <small
                            v-if="!v$.type.required && v$.type.$dirty"
                            class="text-danger ml-3"
                        >
                            PLease select a task type
                        </small>

                        <base-task-notification-control
                            v-on:notify="notification = $event"
                        />
                    </div>

                    <!-- create task naming fields -->
                    <div class="task-input-items">
                        <base-label-control for="title">
                            Tytuł zadania
                        </base-label-control>

                        <small
                            v-if="!v$.title.required && v$.title.$dirty"
                            class="text-danger mr-3"
                        >
                            Task title is required
                        </small>
                        <small
                            v-if="!v$.title.alpha && v$.title.dirty"
                            class="text-danger mr-3"
                        >
                            Task title is required
                        </small>

                        <base-text-input-control
                            class="taskTitle"
                            type="text"
                            id="title"
                            placeholder="Przygotowanie tekstów"
                            v-model="title"
                        />

                        <base-label-control for="category" class="mt-3">
                            Domena
                        </base-label-control>

                        <small
                            v-if="!v$.domain.required && v$.domain.$dirty"
                            class="text-danger mr-3"
                        >
                            A valid domaim is required
                        </small>

                        <base-text-input-control
                            class="taskUrl"
                            type="url"
                            id="category"
                            placeholder="wpisz nazwę domeny"
                            v-model="domain"
                        />
                    </div>

                    <!-- Categories -->
                    <small
                        v-if="!v$.category_id.required && v$.category_id.$dirty"
                        class="text-danger mt-3"
                    >
                        Please choose a Kanał i kategoria tag
                    </small>

                    <create-task-category-tags
                        v-on:selectedChannelId="category_id = $event"
                    />

                    <small
                        v-if="!v$.state_id.required && v$.state_id.$dirty"
                        class="text-danger"
                    >
                        Please choose a state tag
                    </small>

                    <!-- state TAGS-->
                    <create-task-state-select
                        v-on:selectedTag="state_id = $event"
                    /><!-- state TAGS end -->

                    <!-- contractor  -->
                    <create-task-contractor
                        v-on:contactorId="contractor_id = $event"
                    />

                    <!-- description -->
                    <create-task-desc-info
                        v-on:recievedDesc="description = $event"
                    />
                    <!-- description end-->

                    <div class="mt-5">
                        <p v-if="submitStatus === 'OK'">
                            <span class="text-success">Success!</span> Task has
                            been succesfully created!
                        </p>

                        <p v-if="submitStatus === 'ERROR'">
                            <span class="text-danger">Field errors! </span
                            >Please fill the form correctly.
                        </p>

                        <p
                            v-if="submitStatus === 'PENDING'"
                            class="text-warning"
                        >
                            Sending...
                        </p>
                    </div>

                    <button class="btn btn-primary mt-5">
                        Ultworz zadanie
                    </button>
                </form>
            </div>

            <div class="col-4 bg-light">
                <!-- files -->
                <create-task-add-file></create-task-add-file>
                <!-- files end -->

                <!-- assignmemt -->
                <create-task-assignation></create-task-assignation>
                <!-- assignmemt end-->

                <!-- watchers  -->
                <create-task-watchers></create-task-watchers>
                <!-- watchers  end-->
            </div>
        </div>
    </div>
</template>

<script>
import { required, numeric } from "@vuelidate/validators";
import { useVuelidate } from '@vuelidate/core'

const validdateUrl = (value) => {
    new RegExp(/^(ftp|http):\/\/[^ "]+$/).test(value);
};

export default {
    data() {
        return {
            submitStatus: null,
            due_date: null,
            priority: null,
            type: [],
            notification: false,
            title: "",
            domain: "",
            category_id: null,
            state_id: [],
            contractor_id: null,
            description: "",
            disabled: true,
        };
    },

    setup: () => ({ v$: useVuelidate() }),
    validations: {
        due_date: {
            required,
        },
        priority: {
            required,
            numeric,
        },
        type: {
            required,
        },
        title: {
            required,
        },
        domain: {
            required,
            validdateUrl,
        },
        category_id: {
            required,
        },
        state_id: {
            required,
        },
    },

    methods: {
        submitForm() {
            this.v$.$touch();

            if (this.v$.$invalid) {
                this.submitStatus = "ERROR";
            } else {
                this.submitStatus = "PENDING";
                axios
                    .post(this.appEndpoint("/api/task/create"), {
                        due_date: this.due_date,
                        priority: this.priority,
                        type: this.type,
                        title: this.title,
                        domain: this.domain,
                        category_id: this.category_id,
                        state_id: this.state_id,
                        contractor_id: this.contractor_id,
                        description: this.description,
                    })
                    .then((response) => {
                        this.submitStatus = "OK";
                    })
                    .catch((error) => console.log(error.response.data));
            }
        },

        navigateToTaskResources() {
            this.$router.back();
        },

        domainChecker(url) {
            const domainValidator = new RegExp(/^(ftp|http|https):\/\/[^ "]+$/);
            domainValidator.test(url);
        },
    },
};
</script>
